// Emoji
export const EMOJI_SELECTED = "emojiSelected"

// JD
export const JD_UN_FAV = "jdUnFav"
export const JD_EDIT = "jdEdit"
export const JD_RESUME = "jdResume"
export const JD_DEL = "jdDelete"
export const JD_BECOME_REFEREE = "jdBecomeReferee"
export const JD_CANCEL_REFEREE = "jdCancelReferee"
export const JD_REFRESH_REFEREE = "jdRefreshReferee"
export const JD_OFFLINE = "jdOffline"
export const JD_ONLINE = "jdOnline"
export const JD_REJECT = "jdReject"
export const JD_OFFLINE_BY_ADMIN = "jdOfflineByAdmin"
export const JD_ONLINE_BY_ADMIN = "jdOnlineByAdmin"

// CV
export const CV_PREVIEW = "cvPreview"
export const CV_EDIT = "cvEdit"
export const CV_CLONE = "cvClone"
export const CV_DEL = "cvDelete"
export const CV_REFRESH = "cvRefresh"

// Referee
export const REFEREE_CV_SELECT = "refereeCvSelect"
export const REFEREE_CHAT = "refereeChat"

// Post
export const POST_REFRESH = "postRefresh"

// admin audit
export const AUDIT_REFEREE_AUTH_INFO = "auditRefereeAuthInfo"
export const AUDIT_COMPANY_AUTH_INFO = "auditCompanyAuthInfo"
export const AUDIT_USER_AUTH_INFO = "auditUserAuthInfo"
export const AUDIT_JD_AUTH_INFO = "auditJdAuthInfo"
export const AUDIT_REAL_NAME_AUTH_INFO = "auditIdentityAuthInfo"
export const AUDIT_WITHDRAW_AUTH_INFO = "auditWithdrawAuthInfo"


// Bank Card
export const BANK_CARD_REFRESH = "bankCardRefresh"

