
import * as rpc from "@/constants/rpc";
import Carousel from "@/models/carousel";

export default {
    name: "Carousel",
    data() {
        return {
            loading: true,
            carouselList: [],
        }
    },
    created() {
        this.getCarousels()
    },
    methods: {
        onImageClick(item) {
            if (item?.linkUrl) {
                sessionStorage.setItem('store', JSON.stringify(this.$store.state))
                window.open(item?.linkUrl, '_blank')
            }
        },
        async getCarousels() {
            const ret = await this.$axios.rpc(rpc.OPERATION, rpc.CAROUSEL_GET)
            if (ret && ret.result) {
                let newArray = []
                for (let i = 0; i < ret.result.length; i++) {
                    let p = new Carousel(ret.result[i])
                    newArray.push(p)
                }
                this.carouselList = newArray
                this.loading = false
            } else {
                this.carouselList = []
                this.loading = true
            }
        },
    }
}
