import { render, staticRenderFns } from "./index.vue?vue&type=template&id=4acbd9e2&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=4acbd9e2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4acbd9e2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CmnHeader: require('/ntweb/components/cmn-header.vue').default,JobAside: require('/ntweb/components/job-aside.vue').default,SearchBar: require('/ntweb/components/search-bar.vue').default,Carousel: require('/ntweb/components/carousel.vue').default,HomeTabBar: require('/ntweb/components/home-tab-bar.vue').default,JdList: require('/ntweb/components/jd-list.vue').default,SkeletonTabBar: require('/ntweb/components/skeleton-tab-bar.vue').default,SkeletonJdItem: require('/ntweb/components/skeleton-jd-item.vue').default,CmnFooter: require('/ntweb/components/cmn-footer.vue').default})
