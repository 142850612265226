
import {HOME_RANK_HOT, HOME_RANK_LATEST, HOME_RANK_RECOMMEND} from "@/constants/rank";

export default {
    name: "HomeTabBar",
    props: {
        usage: String,
        loading: Boolean,
    },
    data() {
        return {
            activeName: 'tab1'
        }
    },
    computed: {
        getLabel() {
            switch (this.usage) {
                case HOME_RANK_LATEST:
                    return "最新职位"
                case HOME_RANK_HOT:
                    return "热门职位"
                case HOME_RANK_RECOMMEND:
                    return "为我推荐"
                default:
                    return ""
            }
        },
    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        },
        onRefresh() {
            this.$emit("refresh",this.usage)
        },
    }
}
