
export default class Carousel {

    constructor(options) {
        this._id = options?._id || 0
        this.orderNo = options?.orderNo || 0
        this.imageKey = options?.imageKey || ""
        this.imageUrl = options?.imageUrl || ""
        this.linkUrl = options?.linkUrl || ""
    }

    get isNew() {
        return this._id <= 0
    }

    toData() {
        return {
            _id: this._id,
            orderNo: this.orderNo,
            imageKey: this.imageKey,
            imageUrl: this.imageUrl,
            linkUrl: this.linkUrl
        }
    }

    toJSON () {
        return { ...this }
    }
}
