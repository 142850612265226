

import * as rpc from "@/constants/rpc";
import Jd from "@/models/jd";
import {HOME_RANK_HOT, HOME_RANK_LATEST, HOME_RANK_RECOMMEND} from "@/constants/rank";
import {E_NOT_FOUND, E_UNAUTHORIZED} from "@/constants/err";
import user from "@/mixins/user";

export default {
    mixins: [user],
    data() {
        return {
            latest: {
                loading: false,
                jds: [],
                pageCurrent: 1,
                pageSize: 20,
                total: 0,
            },
            hot: {
                loading: false,
                jds: [],
                pageCurrent: 1,
                pageSize: 20,
                total: 0,
            },
            recommend: {
                loading: false,
                jds: [],
                pageCurrent: 1,
                pageSize: 20,
                total: 0,
            }
        };
    },
    created() {
        this.getRecommendJds()
        this.getLatestJds()
        this.getHotJds()
    },
    methods: {
        manualLogin() {
            this.getRecommendJds()
        },
        onRefresh(usage) {
            switch (usage) {
                case HOME_RANK_LATEST:
                    this.increaseLatestPage()
                    this.getLatestJds()
                    break;
                case HOME_RANK_HOT:
                    this.increaseHotPage()
                    this.getHotJds()
                    break;
                case HOME_RANK_RECOMMEND:
                    this.increaseRecommendPage()
                    this.getRecommendJds()
                    break;
                default:
                    break;
            }
        },
        increaseLatestPage() {
            this.latest.pageCurrent++
            let page = this.latest.total / this.latest.pageSize
            const mod = this.latest.total % this.latest.pageSize
            if (mod>0) {
                page++
            }
            if (this.latest.pageCurrent > page) {
                this.latest.pageCurrent = 1
            }
        },
        increaseHotPage() {
            this.hot.pageCurrent++
            let page = this.hot.total / this.hot.pageSize
            const mod = this.hot.total % this.hot.pageSize
            if (mod>0) {
                page++
            }
            if (this.hot.pageCurrent > page) {
                this.hot.pageCurrent = 1
            }
        },
        increaseRecommendPage() {
            if (!this.isLogin) {
                return
            }
            this.recommend.pageCurrent++
            let page = this.recommend.total / this.recommend.pageSize
            const mod = this.recommend.total % this.recommend.pageSize
            if (mod>0) {
                page++
            }
            if (this.recommend.pageCurrent > page) {
                this.recommend.pageCurrent = 1
            }
        },
        async getLatestJds() {
            this.latest.loading = true
            const params = {
                pageNum: this.latest.pageCurrent - 1,
                pageSize: this.latest.pageSize,
            }
            const ret = await this.$axios.rpcMsg(rpc.CONTENT, rpc.RANK_JD_LATEST, params, [E_NOT_FOUND])
            if (ret && ret.result) {
                this.latest.jds = this.prepareJds(ret.result.jds)
                this.latest.total = ret.result.count
            }
            this.latest.loading = false
        },
        async getHotJds() {
            this.hot.loading = true
            const params = {
                pageNum: this.hot.pageCurrent - 1,
                pageSize: this.hot.pageSize,
            }
            const ret = await this.$axios.rpcMsg(rpc.CONTENT, rpc.RANK_JD_HOT, params, [E_NOT_FOUND])
            if (ret && ret.result) {
                this.hot.jds = this.prepareJds(ret.result.jds)
                this.hot.total = ret.result.count
            }
            this.hot.loading = false
        },
        async getRecommendJds() {
            if (!this.isLogin) {
                return
            }
            this.recommend.loading = true
            const params = {
                pageNum: this.recommend.pageCurrent,
                pageSize: this.recommend.pageSize,
            }
            const ret = await this.$axios.rpcMsg(rpc.SEARCH, rpc.SEARCH_RECOMMEND_JDS, params, [E_NOT_FOUND,E_UNAUTHORIZED])
            if (ret && ret.result) {
                this.recommend.jds = this.prepareJds(ret.result.jds)
                this.recommend.total = ret.result.count
            }
            this.recommend.loading = false
        },
        prepareJds(jds) {
            let newArray = []
            if (jds && Array.isArray(jds)) {
                for (let i = 0; i < jds.length; i++) {
                    let jd = new Jd(jds[i])
                    newArray.push(jd)
                }
            }
            return newArray
        }
    }
}

