
import json from "../assets/datas/jobs.json"

export default {
    name: 'JobAside',
    data() {
        return {
            activeName: 2,
            jobs: json.result
        };
    },
    methods: {
        onSearch(item) {
            this.$router.push("/search?q=" + item)
        }
    }
}
